<template>
  <v-container fluid ma-0 pa-0>
    <Navbar />
    <v-container class="body-container">
      <div
        class="text-h1 font-weight-bold text-center text-sm-left my-16 pt-md-3"
      >
        ¡Hola {{ provider.name }}!
      </div>
      <!-- <div class="text-h5 font-weight-bold mt-10 text-center text-sm-left">
        Estadísticas generales
      </div>
      <div class="my-6">
        <DashboardHome />
      </div> -->

      <div class="text-h2 font-weight-bold text-center text-sm-left mt-5 mb-4">
        Mis solicitudes de cotización
      </div>
      <RequestsTable
        :filter-status="filterStatusRequests"
        :loading="loadingQuotationRequests"
        :pagination-status="false"
      />
      <div class="text-center mt-2">
        <router-link
          class="text-h4 text-decoration-underline font-weight-bold"
          style="color: inherit"
          :to="getRequestsRoute"
        >
          Ver todas
        </router-link>
      </div>

      <div
        class="text-h2 font-weight-bold text-center text-sm-left mt-16 mt-md-5 mb-4"
      >
        Reservaciones
      </div>
      <ReservationTable
        :filter-status-reservations="filterStatusReservations"
        :loading="loadingReservations"
        :pagination-status="false"
      />
      <div class="text-center mt-2">
        <router-link
          class="text-h4 text-decoration-underline font-weight-bold"
          style="color: inherit"
          :to="getReservationsRoute"
        >
          Ver todas
        </router-link>
      </div>
    </v-container>

    <Footer class="mt-16" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Footer from "@/components/Shared/Footer.vue";
import ReservationTable from "@/components/Provider/Reservation/ReservationTable.vue";
import RequestsTable from "@/components/Provider/Request/RequestsTable.vue";
// import DashboardHome from "@/components/Provider/Homepage/DashboardHome.vue";
import { mergeProductsLists } from "@/Utils/Utils.js";
import {
  GET_PROVIDER_QUOTATION_REQUESTS,
  GET_PROVIDER_RESERVATIONS,
} from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { mapState } from "vuex";
export default {
  components: {
    Navbar,
    // DashboardHome,
    ReservationTable,
    RequestsTable,
    Footer,
  },
  data: () => ({
    loadingQuotationRequests: true,
    loadingReservations: true,
    quotationRequests: [],
    reservations: [],
  }),
  computed: mapState({
    provider(state) {
      if (state.provider) {
        return state.provider;
      } else {
        return { photoUrl: null };
      }
    },
    filterStatusRequests() {
      return this.quotationRequests.slice(0, 3);
    },
    filterStatusReservations() {
      return this.reservations.slice(0, 3);
    },
    getRequestsRoute() {
      return { name: "ProviderRequests" };
    },
    getReservationsRoute() {
      return { name: "ProviderReservations" };
    },
    getProfileRoute() {
      return { name: "ProviderProfile" };
    },
  }),
  watch: {
    provider: {
      async handler(provider) {
        if (!provider || !provider.id)
          this.$router.push({ name: "RegisterProvider" });
        this.fetchQuotationRequests(parseInt(provider.id));
        this.fetchReservations(parseInt(provider.id));
      },
      immediate: true,
    },
  },
  methods: {
    async fetchQuotationRequests(providerId) {
      const { data, errors } = await useQuery(GET_PROVIDER_QUOTATION_REQUESTS, {
        providerId: providerId,
      });
      if (data) {
        this.quotationRequests = this.formatQuotationRequests(
          data.providerQuotations
        );
      } else if (errors) {
        console.log(errors);
      }
      this.loadingQuotationRequests = false;
    },
    async fetchReservations(providerId) {
      const { data, errors } = await useQuery(GET_PROVIDER_RESERVATIONS, {
        providerId,
      });
      if (data) {
        this.serviceReservations = this.formatServiceReservations(
          data.providerReservations ? data.providerReservations.service : []
        );
        this.packageReservations = this.formatPackageReservations(
          data.providerPackageReservations || []
        );
        const mergedReservations = mergeProductsLists(
          this.serviceReservations,
          this.packageReservations
        );
        this.reservations = this.getFutureReservations(mergedReservations);
      } else if (errors) {
        console.log(errors);
      }
      this.loadingReservations = false;
    },
    formatQuotationRequests(quotations) {
      return quotations.map((quotation) => {
        return {
          ...quotation.quotationRequest,
          serviceName: quotation.service.name,
        };
      });
    },

    getFutureReservations(reservations) {
      return reservations.filter(function (item) {
        return item.status !== "completed";
      });
    },
    formatServiceReservations(reservations) {
      let formattedList = [];
      reservations.map((item) => {
        item.serviceQuotationRequests.map((data) => {
          formattedList.push({
            name: data.requesterName,
            id: data.reservation !== null ? data.reservation.id : 0,
            phoneNumber: data.requesterPhone,
            productName: item.name,
            email: data.requesterEmail,
            date: data.eventDate,
            status: data.status,
            productType: "Servicio",
          });
        });
      });
      return formattedList;
    },
    formatPackageReservations(reservations) {
      const formattedList = reservations.map((reservation) => {
        return {
          name: reservation.userName,
          id: reservation.id,
          phoneNumber: reservation.userPhone,
          productName: reservation.packageName,
          email: reservation.userEmail,
          date: reservation.eventDate,
          status: reservation.status,
          productType: "Paquete",
        };
      });
      return formattedList;
    },
  },
};
</script>
